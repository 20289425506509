<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Citazioni</h4>
                </div>
                <DataTable :value='augmentedRows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='author' header='Autore' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.author }}
                        </template>
                    </Column>

                    <Column field='pub_at' header='Pubblicazione' :sortable='true' style='min-width:12rem' class='py-0'>
                        <template #body='slotProps'>
                            <ul v-if='slotProps.data.translations' class='publish_states_x_language my-0'>
                                <li :key='"quotes_el_"+slotProps.data.id+"_"+lang' v-for='(t, lang) in slotProps.data.translations' class='my-2'>
                                    <Flag v-if='lang' :code="lang" :name="languageLabel(lang)" :width='24' :class="{'mr-1': true}"/>
                                    <PublishStateBadge :code='t.publish_state'/>
                                    <span v-if='t.pub_at' class='ml-2'> {{ publishDate(t.pub_at) }}</span>
                                </li>
                            </ul>
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/Quotes');
const commonStore = createNamespacedHelpers('Common');

import ListHeader from '../../../components/4books/ListHeader';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PublishStateBadge from '../../../components/4books/PublishStateBadge';
import tools from '../../../libs/tools';

export default {
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        ...commonStore.mapGetters(['languages']),
        augmentedRows(){
            if (this.rows && this.rows.length){
                return this.rows.map(row => {
                    row['pub_at'] = '-';
                    if (row['translation'] && row['translation']['pub_at']){
                        row['pub_at'] = row['translation']['pub_at'];
                        return row;
                    }
                    if (row['translations'] && (typeof row['translations'] === 'object')){
                        let found = false;
                        Object.keys(row['translations']).forEach(l => {
                            if (found) return;
                            const t = row['translations'][l];
                            if (t['pub_at']){
                                row['pub_at'] = t['pub_at'];
                            }
                        });
                    }
                    return row;
                })
            }
            return [];
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList']),
        publishDate(ts){
            return tools.time.lastUpdateFromTs(ts);
        },
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({});
        },
        clearFilter() {
            this.initFilters();
        },
    },
    mixins: [
        Notifications,
        Navigations,
    ],
    components: {
        PublishStateBadge,
        ListHeader
    }
};
</script>
